import React, { useContext } from 'react';
import * as S from './BandanaSelector.styles';
import Select, { components } from 'react-select';
import FunnelContext from '../../../context/FunnelContext';

export const BandanaSelector = props => {
  const { show, items } = props;

  const { extraObjects, setExtraObjects } = useContext(FunnelContext);

  const handleSelectChange = ({ value }) => {
    setExtraObjects(
      Object.assign(extraObjects, {
        bandana_variant_id: value
      })
    );
  };
  const options = items?.map(item => {
    item.value = item.variantId;
    return item;
  });

  const { Option, SingleValue } = components;
  const IconOption = props => (
    <Option {...props}>
      <S.OptionImage src={props.data.icon} alt={props.data.label} />
      <S.OptionLabel>{props.data.label}</S.OptionLabel>
    </Option>
  );

  const SingleValue2 = props => (
    <SingleValue {...props}>
      <S.OptionImage src={props.data.icon} alt={props.data.label} />
      <S.OptionLabel>{props.data.label}</S.OptionLabel>
    </SingleValue>
  );
  if (show && options) {
    return (
      <S.Container>
        <S.Label>
          Bandana Size <span>(FREE for subscribers)</span>
        </S.Label>
        <Select
          classNamePrefix="bandana-select"
          options={options}
          onChange={handleSelectChange}
          inputProps={{ readOnly: true }}
          isSearchable={false}
          defaultValue={options.filter(
            option => option.value === extraObjects?.bandana_variant_id
          )}
          components={{ Option: IconOption, SingleValue: SingleValue2 }}
        />
      </S.Container>
    );
  } else {
    return ``;
  }
};

export default BandanaSelector;
